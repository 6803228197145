import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { 
  Box, 
  Container,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { makeRequest } from '../api/Api';

const Potongan = () => {
  const [potongan, setPotongan] = useState([]);
  const [reload, setReload] = useState(0);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const loadPotongan = () => {
    makeRequest({
      url: 'potongan',
      successCallback: (data) => {
        // console.log(data);
        setPotongan(data.potongan.jumlah);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const editPotongan = () => {
    const values = { jumlah: potongan };
    makeRequest({
      url: 'potongan',
      values,
      successCallback: (data) => {
        // console.log(data);
        setMsg(data.msg);
        setOpen(true);
        setReload(reload + 1);
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={() => setOpen(false)}>
        Ok
    </Button>
  );
  useEffect(() => {
    loadPotongan();
  }, [reload]);
  return (
        <>
        <Helmet>
        <title>Data Master Pekerjaan</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
            {
              open ? (
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  open={open}
                  autoHideDuration={2000}
                  message={msg}
                  action={action}
                />
              ) : (
                null
              )
          }
            <Container maxWidth="lg">
            <Card>
              <CardHeader title="Atur Potongan Tunjangan" />
              <Divider />
              <CardContent>
                  <Grid container spacing={3}> 
                    <Grid item xs={12} sm={4}>
                    <TextField 
                      margin="dense"
                      label="Potongan Tunjangan"
                      type="text"
                      fullWidth
                      autoFocus
                      value={potongan}
                      onChange={(evt) => { setPotongan(evt.target.value); }}
                    />
                    </Grid>
                  
                    <Grid item xs={12} sm={4}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={editPotongan}
                      sx={{ mt: 2 }}
                    >
                      Edit Data
                    </Button>
                    </Grid>
                  </Grid>
              </CardContent>
            </Card>
            </Container>
        </Box>
        </>
  );
};

export default Potongan;
