import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import CustomerList from './pages/CustomerList';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Register from './pages/Register';
import Master from './pages/Master';
import Absen from './pages/Absen';
import Pekerjaan from './pages/Pekerjaan';
import PerilakuKerja from './pages/PerilakuKerja';
import Skp from './pages/Skp';
import LaporanHarian from './components/skp/LaporanHarian';
import DetilHarian from './components/skp/DetilHarian';
import CeklisKerjaan from './components/skp/CeklisKerjaan';
import NilaiPerilaku from './components/skp/NilaiPerilaku';
import LapPenilaian from './components/skp/LapPenilaian';
import Kehadiran from './components/absen/Kehadiran';
import Potongan from './pages/Potongan';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList page={{ hal: 'daftar' }} /> },
      { path: 'add_pegawai', element: <CustomerList page={{ hal: 'add' }} /> },
      { path: 'edit_pegawai', element: <CustomerList page={{ hal: 'edit' }} /> },
      { path: 'absen', element: <Absen page={{ hal: 'daftar' }} /> },
      { path: 'input_absen', element: <Absen page={{ hal: 'add' }} /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'jabatan', element: <Master /> },
      { path: 'pekerjaan', element: <Pekerjaan /> },
      { path: 'perilaku', element: <PerilakuKerja /> },
      { path: 'skp', element: <Skp page={{ hal: 'kinerja' }} /> },
      { path: 'target', element: <Skp page={{ hal: 'target' }} /> },
      { path: 'capaian', element: <Skp page={{ hal: 'capaian' }} /> },
      { path: 'register', element: <Register /> },
      { path: 'lap_harian', element: <LaporanHarian /> },
      { path: 'detil_lap', element: <DetilHarian /> },
      { path: 'ceklis', element: <CeklisKerjaan /> },
      { path: 'nilai_perilaku', element: <NilaiPerilaku /> },
      { path: 'lap_penilaian', element: <LapPenilaian /> },
      { path: 'kehadiran', element: <Kehadiran /> },
      { path: 'potongan', element: <Potongan /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
