import { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography
} from '@material-ui/core';

const Welcome = () => {
  return (
    <>
      <Card>
          <CardContent>
              <Typography variant="h1" component="h2">
                  Selamat Datang.
              </Typography>
              <Typography>
                  Ini Merupakan Aplikasi Penilaian Kinerja Pegawai Di Desa Kunyit
              </Typography>
          </CardContent>
      </Card>
    </>
  );
};

export default Welcome;
