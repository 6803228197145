import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import MasterPekerjaan from '../components/master/MasterPekerjaan';
import { makeRequest } from '../api/Api';

const Pekerjaan = () => {
  const [pekerjaan, setPekerjaan] = useState([]);
  const [reload, setReload] = useState(0);

  const handleReload = (val) => {
    setReload(val);
  };

  const loadPekerjaan = () => {
    makeRequest({
      url: 'pekerjaan',
      successCallback: (data) => {
        setPekerjaan(data.pekerjaan);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  useEffect(() => {
    loadPekerjaan();
  }, [reload]);

  return (
    <>
      <Helmet>
        <title>Data Master Pekerjaan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <MasterPekerjaan pekerjaan={pekerjaan} callBackReload={handleReload} reload={reload} />
        </Container>
      </Box>
    </>
  );
};

export default Pekerjaan;
