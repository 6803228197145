import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Snackbar from '@material-ui/core/Snackbar';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Edit, Trash2 } from 'react-feather';
import getInitials from '../../utils/getInitials';
import { makeRequest } from '../../api/Api';

const CustomerListResults = ({ callBackData }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [pegawai, setPegawai] = useState([]);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [reload, setReload] = useState(0);

  const navigate = useNavigate();

  const urlFoto = 'https://www.skp.kunyit.tanahlautkab.go.id/backend/upload/pegawai/';

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleEditClicked = (dataPegawai) => {
    callBackData(dataPegawai);
    navigate('/app/edit_pegawai', { replace: false });
  };

  const hapusPegawai = (id) => {
    Swal.fire({
      title: 'Yakin Hapus Data Ini?',
      text: 'Data Akan Terhapus Dari Sistem',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus Data!',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        makeRequest({
          url: `pegawai/${id}`,
          successCallback: (data) => {
            setMsg(data.msg);
            setOpen(true);
            setReload(reload + 1);
          },
          failureCallback: (err) => {
            console.log(err);
          },
          requestType: 'DELETE'
        });
      }
    });
  };

  const loadPegawai = () => {
    makeRequest({
      url: 'pegawai',
      successCallback: (data) => {
        // console.log(data);
        setPegawai(data.pegawai);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={() => setOpen(false)}>
      Ok
    </Button>
  );

  useEffect(() => {
    loadPegawai();
  }, [reload]);

  return (
    <Card>
      {
          open ? (
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={open}
              autoHideDuration={2000}
              message={msg}
              action={action}
            />
          ) : (
            null
          )
        }
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  No
                </TableCell>
                <TableCell>
                  Nama
                </TableCell>
                <TableCell>
                  Nip
                </TableCell>
                <TableCell>
                  Jenis Kelamin
                </TableCell>
                <TableCell colSpan={2}>
                  Jabatan
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                pegawai.lenght === 0 ? (
                  <TableRow>
                      <TableCell colSpan="5">
                        Loading ......
                      </TableCell>
                  </TableRow>
                ) : (
                  pegawai.slice(page * limit, page * limit + limit).map((pgw, idx) => (
                    <TableRow key={pgw.peg_id}>
                      <TableCell>
                        {idx + 1}
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Avatar
                            src={urlFoto + pgw.foto}
                            sx={{ mr: 2 }}
                          >
                            {getInitials(pgw.nama)}
                          </Avatar>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                          >
                            {pgw.nama}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {pgw.nip}
                      </TableCell>
                      <TableCell>
                        {pgw.jk === 'L' ? 'Laki-Laki' : 'Perempuan'}
                      </TableCell>
                      <TableCell>
                        {pgw.nama_jabatan}
                      </TableCell>
                      <TableCell>
                        <IconButton sx={{ color: '#ff6f00' }} onClick={() => handleEditClicked(pgw)}>
                          <Edit />
                        </IconButton>
                        <IconButton sx={{ color: '#f44336' }} onClick={() => hapusPegawai(pgw.peg_id)}>
                          <Trash2 />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )
            }
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pegawai.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

export default CustomerListResults;
