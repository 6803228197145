import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Trash } from 'react-feather';
import Swal from 'sweetalert2';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import { makeRequest } from '../../api/Api';
import { loadDataPegawai, saveNilai } from '../../api/LocalStorage';

const CapaianSkp = () => {
  const bulan = ['--Pilih Bulan--', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  const cek = moment(new Date(), 'YYYY/MM/DD');
  const [bulanSekarang, setBln] = useState(bulan[cek.format('M')]);
  const [tahunSekarang, setThn] = useState(cek.format('Y'));
  const [capaianSkp, setCapaian] = useState([]);
  const [msg, setMsg] = useState('');
  const [notif, setNotif] = useState(false);
  const dataPegawai = JSON.parse(loadDataPegawai());
  const idPegawai = dataPegawai.id;

  const [filter, setFilter] = useState({
    bulan: bulanSekarang,
    tahun: tahunSekarang
  });

  const handleChange = (evt) => {
    setFilter({
      ...filter,
      [evt.target.name]: evt.target.value,
    });
  };

  const loadSkp = () => {
    makeRequest({
      url: `skp_pegawai/${idPegawai}/${bulan[cek.format('M')]}/${cek.format('Y')}`,
      successCallback: (data) => {
        setCapaian(data.skp);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const cariSkp = () => {
    makeRequest({
      url: `skp_pegawai/${idPegawai}/${filter.bulan}/${filter.tahun}`,
      successCallback: (data) => {
        setCapaian(data.skp);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const hapusSkp = (idSkp) => {
    const values = {
      id_skp: idSkp,
      bln: filter.bulan,
      thn: filter.tahun
    };
    Swal.fire({
      title: 'Yakin Hapus Data Ini?',
      text: 'Data Akan Terhapus Dari Sistem',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus Data!',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        makeRequest({
          url: 'hapus_skp',
          values,
          successCallback: (data) => {
            console.log(data);
            setMsg(data.msg);
            setNotif(true);
            loadSkp();
          },
          failureCallback: (err) => {
            console.log(err);
          }
        });
      }
    });
  };

  const HitungJumlah = () => {
    let jml = 0;
    if (capaianSkp.length > 0) {
      const jmlNilai = capaianSkp.reduce((akumulasi, item) => akumulasi + parseInt(item.nilai_capaian, 10), 0);
      const jmlPekerjaanBlmNilai = capaianSkp.filter((obj) => !Object.values(obj).includes('0')).length;
      jml = jmlNilai / capaianSkp.length - jmlPekerjaanBlmNilai;
    } else {
      jml = 0;
    }
    saveNilai(jml);
    return jml;
  };

  const KetNilai = () => {
    const nilai = HitungJumlah();
    let ket = '';
    if (nilai <= 50) {
      ket = 'Buruk';      
    } else if (nilai <= 60) {
      ket = 'Sedang';
    } else if (nilai <= 75) {
      ket = 'Cukup';
    } else if (nilai <= 99) {
      ket = 'Baik';
    } else {
      ket = 'Sangat Baik';
    }
    return ket;
  };

  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={() => setNotif(false)}>
      Ok
    </Button>
  );

  useEffect(() => {
    loadSkp();
  }, []);

  return (
      <>
      {
          notif ? (
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={notif}
              autoHideDuration={2000}
              message={msg}
              action={action}
            />
          ) : (
            null
          )
        }
        <Card>
          <CardHeader title="Target & Capaian SKP Pegawai" />
          <Divider />
            <CardContent>
              <Grid container spacing={2} sx={{ marginTop: 0.5 }}>
                  <Grid item xs={4} md={3}> 
                      <Typography variant="body1" align="left">
                          Nama Lengkap:
                          {'\n'}
                          {dataPegawai.nama}
                      </Typography>
                  </Grid>
                  <Grid item xs={4} md={3}>
                      <Typography variant="body1" align="center">
                          Jabatan:
                          {'\n'} 
                          {dataPegawai.jabatan}
                      </Typography>
                  </Grid>
                  <Grid item xs={4} md={2}>
                      <TextField 
                        fullWidth
                        label="Bulan"
                        variant="outlined"
                        name="bulan"
                        select
                        SelectProps={{ native: true }}
                        value={filter.bulan}
                        onChange={handleChange}
                      >
                          {
                            bulan.map((bln) => (
                              <option key={bln} value={bln}>
                                {bln}
                              </option>
                            ))
                                    }
                      </TextField>
                  </Grid>
                  <Grid item xs={4} md={2}>
                      <TextField
                        fullWidth
                        label="Tahun"
                        variant="outlined"
                        name="tahun"
                        value={filter.tahun}
                        onChange={handleChange}
                      />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ mt: 1 }}
                      onClick={cariSkp}
                    >
                    Cari
                    </Button>
                  </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid item xs={4} md={3}>
                <Typography variant="body1" align="left">
                  Jumlah Nilai:
                  {'\n'} 
                  <HitungJumlah />
                  {'\n'}
                  (
                    <KetNilai />
                  )
                  
                </Typography>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
            <PerfectScrollbar>
              <Box>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell rowSpan="2" colSpan="2">
                        No
                      </TableCell>
                      <TableCell rowSpan="2" style={{ paddingRight: '150px' }}>
                        Pekerjaan
                      </TableCell>
                      <TableCell rowSpan="2">
                        Bobot
                      </TableCell>
                      <TableCell rowSpan="2">
                        AK
                      </TableCell>
                      <TableCell colSpan="4" align="center">
                        Target
                      </TableCell>
                      <TableCell rowSpan="2">
                        AK
                      </TableCell>
                      <TableCell colSpan="4" align="center">
                        Realisasi
                      </TableCell>
                      <TableCell rowSpan="2">
                        Penghitungan
                      </TableCell>
                      <TableCell rowSpan="2">
                        Nilai Capaian SKP
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Kuant/Output
                      </TableCell>
                      <TableCell>
                        Kualitas/Mutu
                      </TableCell>
                      <TableCell style={{ paddingRight: '30px' }}>
                        Waktu
                      </TableCell>
                      <TableCell>
                        Biaya
                      </TableCell>
                      <TableCell>
                        Kuant/Output
                      </TableCell>
                      <TableCell>
                        Kualitas/Mutu
                      </TableCell>
                      <TableCell>
                        Waktu
                      </TableCell>
                      <TableCell>
                        Biaya
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  { capaianSkp.length === 0 ? (
                    <TableRow>
                     <TableCell colSpan="15">
                       Data Skp Tidak Ditemukan
                     </TableCell>
                    </TableRow>
                  ) : (
                    capaianSkp.map((skp, idx) => (
                      <TableRow key={skp.skp_id + 1}>
                        <TableCell>
                          {idx + 1}
                        </TableCell>
                        <TableCell>
                          <IconButton sx={{ color: '#f44336' }} title="Hapus Data" onClick={() => { hapusSkp(skp.skp_id); }}>
                            <Trash />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          {skp.pekerjaan}
                        </TableCell>
                        <TableCell>
                          {skp.bobot}
                        </TableCell>
                        <TableCell>
                          {skp.ak_target}
                        </TableCell>
                        <TableCell>
                          {`${skp.target_kuantitas}  ${skp.target_output}`}
                        </TableCell>
                        <TableCell>
                          {skp.target_kualitas}
                        </TableCell>
                        <TableCell>
                          {`${skp.target_waktu} ${skp.target_satuan_waktu}`}
                        </TableCell>
                        <TableCell>
                          {skp.target_biaya}
                        </TableCell>
                        <TableCell>
                          {skp.ak_realisasi === null ? ('-') : (skp.ak_realisasi) }
                        </TableCell>
                        <TableCell>
                          {`${skp.realisasi_kuantitas === undefined ? ('-') : (skp.realisasi_kuantitas)} ${skp.realisasi_output === null ? ('-') : (skp.realisasi_output)}`}
                        </TableCell>
                        <TableCell>
                          {skp.realisasi_kualitas}
                        </TableCell>
                        <TableCell>
                          {`${skp.realisasi_waktu === undefined ? ('-') : (skp.realisasi_waktu)} ${skp.realisasi_satuan_waktu === null ? ('-') : (skp.realisasi_satuan_waktu)}`}
                        </TableCell>
                        <TableCell>
                          {skp.realisasi_biaya}
                        </TableCell>
                        <TableCell>
                          {skp.penghitungan}
                        </TableCell>
                        <TableCell>
                          {skp.nilai_capaian}
                        </TableCell>
                      </TableRow>
                    ))
                  ) }
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
            </CardContent>
        </Card>
      </>
  );
};

export default CapaianSkp;
