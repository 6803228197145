import {
  Typography, Avatar, Card, CardHeader, Box, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton, TablePagination, TextField, CardContent, Grid, Divider
} from '@material-ui/core';
import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import { 
  TrendingUp, Crosshair, CheckCircle, Award, Clipboard
} from 'react-feather';
import getInitials from '../../utils/getInitials';
import { saveDataPegawai } from '../../api/LocalStorage';

const DaftarSkp = ({ dataSkp, callBackData }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  const urlFoto = 'https://www.skp.kunyit.tanahlautkab.go.id/backend/upload/pegawai/';

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const targetSkp = (pegawai) => {
    const dtPegawai = { id: pegawai.peg_id, nama: pegawai.nama, jabatan: pegawai.nama_jabatan };
    saveDataPegawai(JSON.stringify(dtPegawai));
    navigate('/app/target', { replace: false });
  };

  const capaianSkp = (pegawai) => {
    const dtPegawai = { id: pegawai.peg_id, nama: pegawai.nama, jabatan: pegawai.nama_jabatan };
    saveDataPegawai(JSON.stringify(dtPegawai));
    navigate('/app/capaian', { replace: false });
  };

  const ceklisSkp = (pegawai) => {
    const dtPegawai = { 
      id: pegawai.peg_id, 
      nama: pegawai.nama, 
      jabatan: pegawai.nama_jabatan, 
      idSkp: pegawai.skp_id 
    };
    saveDataPegawai(JSON.stringify(dtPegawai));
    navigate('/app/ceklis', { replace: false });
  };

  const perilaku = (pegawai) => {
    const dtPegawai = { 
      id: pegawai.peg_id,
      nama: pegawai.nama, 
      jabatan: pegawai.nama_jabatan,
    };
    saveDataPegawai(JSON.stringify(dtPegawai));
    navigate('/app/nilai_perilaku', { replace: false });
  };

  const lapPenilaian = (pegawai) => {
    const dtPegawai = { 
      id: pegawai.peg_id, 
      nama: pegawai.nama, 
      jabatan: pegawai.nama_jabatan, 
      idSkp: pegawai.skp_id 
    };
    saveDataPegawai(JSON.stringify(dtPegawai));
    navigate('/app/lap_penilaian', { replace: false });
  };

  return (
    <>
     <Box sx={{ mb: 3 }}>
      <Card>
          <CardHeader title="Kinerja Pegawai" />
          <Divider />
          <PerfectScrollbar>
              <Box sx={{ minWidth: 1050 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                No
                            </TableCell>
                            <TableCell>
                                Nama Pegawai
                            </TableCell>
                            <TableCell>
                                Jabatan
                            </TableCell>
                            <TableCell>
                                Kinerja Pegawai
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                           dataSkp.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan="2">
                                    Loading ......
                                </TableCell>
                            </TableRow>
                           ) : (
                             dataSkp.slice(page * limit, page * limit + limit).map((skp, idx) => (
                                <TableRow key={skp.id}>
                                    <TableCell>
                                        {idx + 1}
                                    </TableCell>
                                    <TableCell>
                                      <Box
                                        sx={{
                                          alignItems: 'center',
                                          display: 'flex'
                                        }}
                                      >
                                        <Avatar
                                          src={urlFoto + skp.foto}
                                          sx={{ mr: 2 }}
                                        >
                                            {getInitials(skp.nama)}
                                        </Avatar>
                                        <Typography
                                          color="textPrimary"
                                          variant="body1"
                                        >
                                            {skp.nama}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                        {skp.nama_jabatan}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton sx={{ color: '#1f9c40' }} title="Input Target SKP" onClick={() => targetSkp(skp)}>
                                            <Crosshair />
                                        </IconButton>
                                        <IconButton sx={{ color: '#f70505' }} title="Capaian SKP" onClick={() => capaianSkp(skp)}>
                                            <TrendingUp />
                                        </IconButton>
                                        <IconButton sx={{ color: '#724ae8' }} title="Ceklis Pekerjaan" onClick={() => ceklisSkp(skp)}>
                                            <CheckCircle />
                                        </IconButton>
                                        <IconButton sx={{ color: '#000D6B' }} title="Perilaku Kerja" onClick={() => perilaku(skp)}>
                                            <Award />
                                        </IconButton>
                                        <IconButton sx={{ color: '#CD1818' }} title="Laporan Penilaian" onClick={() => lapPenilaian(skp)}>
                                            <Clipboard />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                             ))
                           )
                        }
                    </TableBody>
                </Table>
              </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={dataSkp.length}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
          />
      </Card>
     </Box>
    </>
  );
};
export default DaftarSkp;
