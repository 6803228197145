import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';

const CustomerListToolbar = (props) => {
  const navigate = useNavigate();
  
  const addPegawai = () => {
    navigate('/app/add_pegawai', { replace: true });
  };

  const handleSearch = (evt) => {
    console.log(evt.target.value);
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        
        <Button
          color="primary"
          variant="contained"
          onClick={addPegawai}
        >
          Tambah Pegawai
        </Button>
      </Box>
    </Box>
  );
};
export default CustomerListToolbar;
