import {
  Card, CardHeader, Box, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton, TablePagination, TextField, CardContent, Grid, Divider
} from '@material-ui/core';
import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Snackbar from '@material-ui/core/Snackbar';
import Swal from 'sweetalert2';
import { Edit, Trash2 } from 'react-feather';
import { makeRequest } from '../../api/Api';

const MasterPekerjaan = ({ pekerjaan, callBackReload, reload }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [editedId, setId] = useState(null);
  const [nmPekerjaan, setNmPekerjaan] = useState(''); 
  const [bobot, setBobot] = useState(0);
  const [btn, setBtn] = useState(1);// btn status, 1 untuk tambah, 2 utk edit
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');

  const click = reload;

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handlePekerjaan = (evt) => {
    setNmPekerjaan(evt.target.value);
  };

  const handleBobot = (evt) => {
    setBobot(evt.target.value);
  };

  const addPekerjaan = () => {
    const values = { pekerjaan: nmPekerjaan, bobot };

    makeRequest({
      url: 'pekerjaan',
      values,
      successCallback: (data) => {
        setBobot(0);
        setNmPekerjaan('');
        callBackReload(click + 1);
        setMsg(data.msg);
        setOpen(true);
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const handleEditClicked = (id, nama, bbot) => {
    setId(id);
    setNmPekerjaan(nama);
    setBobot(bbot);
    setBtn(2);
  };

  const editPekerjaan = () => {
    const values = { pekerjaan: nmPekerjaan, bobot, id: editedId };

    makeRequest({
      url: 'pekerjaan',
      values,
      successCallback: (data) => {
        setBobot(0);
        setNmPekerjaan('');
        callBackReload(click + 1);
        setMsg(data.msg);
        setOpen(true);
        setBtn(1);
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const hapus = (idPekerjaan) => {
    Swal.fire({
      title: 'Yakin Hapus Data Ini?',
      text: 'Data Akan Terhapus Dari Sistem',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus Data!',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        makeRequest({
          url: `pekerjaan/${idPekerjaan}`,
          successCallback: (data) => {
            callBackReload(click + 1);
            setMsg(data.msg);
            setOpen(true);
          },
          failureCallback: (err) => {
            console.log(err);
          },
          requestType: 'DELETE',
        });
      }
    });
  };

  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={() => setOpen(false)}>
        Ok
    </Button>
  );
  return (
    <>
      <Box sx={{ mb: 3 }}>
          {
              open ? (
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  open={open}
                  autoHideDuration={2000}
                  message={msg}
                  action={action}
                />
              ) : (
                null
              )
          }
          <Card>
              <CardHeader title="Tambah Data Pekerjaan" />
              <Divider />
              <CardContent>
                  <Grid container spacing={3}> 
                    <Grid item xs={12} sm={4}>
                    <TextField 
                      margin="dense"
                      label="Nama Pekerjaan"
                      type="text"
                      fullWidth
                      autoFocus
                      value={nmPekerjaan}
                      onChange={handlePekerjaan}
                    />
                    </Grid>
                  
                    <Grid item xs={12} sm={4}>
                    <TextField 
                      margin="dense"
                      label="Bobot"
                      type="number"
                      fullWidth
                      autoFocus
                      value={bobot}
                      onChange={handleBobot}
                    />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    {
                        btn === 1 ? (
                       <Button
                         color="primary"
                         variant="contained"
                         onClick={addPekerjaan}
                         sx={{ mt: 2 }}
                       >
                      Tambah Pekerjaan
                       </Button>
                        ) : (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={editPekerjaan}
                          sx={{ mt: 2 }}
                        >
                      Edit Pekerjaan
                        </Button>
                        ) 
                    }
                    </Grid>
                  </Grid>
              </CardContent>
          </Card>
      </Box>
      <Card>
        <CardHeader title="Data Master Pekerjaan" />
        <Divider />
        <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                No
                            </TableCell>
                            <TableCell>
                                Pekerjaan
                            </TableCell>
                            <TableCell colSpan="2">
                                Bobot
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            pekerjaan.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan="3">
                                        Loading....
                                    </TableCell>
                                </TableRow>
                            ) : (
                              pekerjaan.slice(page * limit, page * limit + limit).map((job, idx) => (
                                <TableRow key={job.id}>
                                    <TableCell>
                                        {idx + 1}
                                    </TableCell>
                                    <TableCell>
                                        {job.pekerjaan}
                                    </TableCell>
                                    <TableCell>
                                        {job.bobot}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton sx={{ color: '#ff6f00' }} onClick={() => { handleEditClicked(job.id, job.pekerjaan, job.bobot); }}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton sx={{ color: '#f44336' }} onClick={() => { hapus(job.id); }}>
                                            <Trash2 />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                              ))
                            )
                        }
                    </TableBody>
                </Table>
            </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={pekerjaan.length}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 30]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
        />
      </Card>
    </>
  );
};

export default MasterPekerjaan;
