import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton
} from '@material-ui/core';
import moment from 'moment';
import { makeRequest } from '../../api/Api';
import { loadDataPegawai } from '../../api/LocalStorage';

const LapPenilaian = () => {
  const bulan = ['--Pilih Bulan--', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  const cek = moment(new Date(), 'YYYY/MM/DD');
  const bulanSekarang = cek.format('M');
  const tahunSekarang = cek.format('Y');
  const [capaianSkp, setCapaian] = useState([]);
  const [perilaku, setPerilaku] = useState([]);

  const [cariBln, setCariBln] = useState(bulan[bulanSekarang]);
  const [cariThn, setCariThn] = useState(tahunSekarang);
  const dataPegawai = JSON.parse(loadDataPegawai());
  const idPegawai = dataPegawai.id;
  
  const loadPenilaiaan = () => {
    makeRequest({
      url: `lap_penilaian/${idPegawai}/${cariBln}/${cariThn}`,
      successCallback: (data) => {
        console.log(data);
        setPerilaku(data.perilaku);
        setCapaian(data.skp);
      },
      failureCallback: (err) => {

      },
      requestType: 'GET'
    });
  };

  const cari = () => {
    setPerilaku([]);
    setCapaian([]);
    makeRequest({
      url: `lap_penilaian/${idPegawai}/${cariBln}/${cariThn}`,
      successCallback: (data) => {
        console.log(data);
        setPerilaku(data.perilaku);
      },
      failureCallback: (err) => {
  
      },
      requestType: 'GET'
    });
  };

  const hitungSkp = () => {
    let jml = 0;
    if (capaianSkp.length > 0) {
      const jmlNilai = capaianSkp.reduce((akumulasi, item) => akumulasi + parseInt(item.nilai_capaian, 10), 0);
      const jmlPekerjaanBlmNilai = capaianSkp.filter((obj) => !Object.values(obj).includes('0')).length;
      jml = jmlNilai / capaianSkp.length - jmlPekerjaanBlmNilai;
    } else {
      jml = 0;
    }
    return jml;
  };

  const jmlSkp = () => (hitungSkp() * (60 / 100));

  const jmlPerilaku = () => {
    let jml = 0;
    if (perilaku.length > 0) {
      jml = perilaku.reduce((akumulasi, item) => akumulasi + parseInt(item.nilai, 10), 0);
    } else {
      jml = 0;
    }
    return jml;
  };

  const nilaiPerilaku = () => ((jmlPerilaku() / perilaku.length) * (40 / 100));

  const nilaiPrestasi = () => (jmlSkp() + nilaiPerilaku());

  const kategori = (val) => {
    let ket = '';
    if (val <= 50) {
      ket = 'Buruk';      
    } else if (val <= 60) {
      ket = 'Kurang';
    } else if (val <= 75) {
      ket = 'Cukup';
    } else if (val <= 99) {
      ket = 'Baik';
    } else {
      ket = 'Sangat Baik';
    }
    return ket;
  };

  useEffect(() => {
    loadPenilaiaan();
  }, []);

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Card sx={{ m: 3 }}>
            <CardHeader title="Cari Laporan Penilaian" />
            <Divider />
                <CardContent>
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                      <Grid item xs={4} md={4}>
                        <TextField
                          fullWidth
                          label="Bulan"
                          variant="outlined"
                          name="bulan"
                          select
                          SelectProps={{ native: true }}
                          value={cariBln}
                          onChange={(evt) => { setCariBln(evt.target.value); }}
                        >
                        {
                            bulan.map((b) => (
                                <option key={b} value={b}>
                                    {b}
                                </option>
                            ))
                              }
                        </TextField>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <TextField 
                          fullWidth
                          label="Tahun"
                          variant="outlined"
                          name="tahun"
                          value={cariThn}
                          onChange={(evt) => { setCariThn(evt.target.value); }}
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={cari}
                        >
                            Cari
                        </Button>
                      </Grid>
                  </Grid>
                </CardContent>
        </Card>
        <Card sx={{ m: 3 }}>
            <CardContent>
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item xs={12} md={12}>
                  <center>
                    <h3>PENILAIAN PRESTASI KERJA APARAT DESA KUNYIT KECAMATAN BAJUIN</h3>
                    <h4>
                        Bulan :
                        {'\n'}
                        {cariBln}
                        {'\n'}
                        {cariThn}
                    </h4>
                  </center> 
                </Grid>
                <Grid item xs={12} md={3}>
                    <Table size="small">
                        <TableRow>
                            <TableCell>
                                Nama : 
                            </TableCell>
                            <TableCell>
                                {dataPegawai.nama}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Jabatan : 
                            </TableCell>
                            <TableCell>
                                {dataPegawai.jabatan}
                            </TableCell>
                        </TableRow>
                    </Table>
                </Grid>
                <Grid item xs={12} md={12}>
                 <Table>
                     <TableHead>
                         <TableRow>
                             <TableCell colSpan="3">
                                 Unsur Yang Dinilai
                             </TableCell>
                             <TableCell>
                                 JUMLAH
                             </TableCell>
                         </TableRow>
                         <TableRow>
                          <TableCell colSpan="2">
                              a. Sasaran Kinerja Pegawai
                          </TableCell>
                          <TableCell>
                              {hitungSkp()}
                              {'\n'}
                              X 60%
                          </TableCell>
                          <TableCell>
                              {jmlSkp()}
                          </TableCell>
                         </TableRow>
                         <TableRow>
                          <TableCell rowSpan={perilaku.length + 4}>
                              b. Perilaku Kerja
                          </TableCell>
                         </TableRow>
                         {
                            perilaku.map((n, idx) => (
                              <TableRow key={n.nilai_id}>
                                  <TableCell>
                                      {idx + 1}
                                      .
                                      {'\n'}
                                      {n.nama}
                                  </TableCell>
                                  <TableCell>
                                      {n.nilai}
                                      {'\n'}
                                      (
                                        {kategori(n.nilai)}
                                      )
                                      
                                  </TableCell>
                                  <TableCell>
                                      -
                                  </TableCell>
                              </TableRow>
                            ))
                         }
                        <TableRow>
                          <TableCell>
                              Jumlah
                          </TableCell>
                          <TableCell>
                              {jmlPerilaku()}
                          </TableCell>
                          <TableCell>
                              -
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                              Nilai Rata-Rata
                          </TableCell>
                          <TableCell>
                              {jmlPerilaku() / perilaku.length}
                              {'\n'}
                              (
                                {kategori(jmlPerilaku() / perilaku.length)}
                              )
                              
                          </TableCell>
                          <TableCell>
                              -
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                              Nilai Perilaku Kerja
                          </TableCell>
                          <TableCell>
                            {jmlPerilaku() / perilaku.length}
                            {'\n'}
                            X 40%
                          </TableCell>
                          <TableCell>
                              {nilaiPerilaku()}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan="3">
                              <center>
                              Nilai Prestasi Kerja
                              </center>
                          </TableCell>
                          <TableCell>
                              {nilaiPrestasi()}
                              {'\n'}
                              (
                                {kategori(nilaiPrestasi())}
                              )
                              
                          </TableCell>
                        </TableRow>
                     </TableHead>
                 </Table>
                </Grid>
              </Grid>
            </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default LapPenilaian;
