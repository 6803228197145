import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import AbsenListResult from '../components/absen/AbsenListResult';
import AddAbsen from '../components/absen/AddAbsen';
import ImportAbsen from '../components/absen/ImportAbsen';

const Absen = (props) => {
  const { page } = props;
  const [pegawai, setPegawai] = useState([]); 

  const RenderPage = () => {
    if (page.hal === 'daftar') {
      return (
            <>
                <ImportAbsen />
                <AbsenListResult />
            </>
      );
    }
    if (page.hal === 'add') {
      return (
        <>
            <AddAbsen />
        </>
      );
    }
    return null;
  };
  return (
    <>
     <Helmet>
         <title>Absen Pegawai</title>
     </Helmet>
     <Box
       sx={{
         backgroundColor: 'background.default',
         minHeight: '100%',
         py: 3
       }}
     >
        <Container maxWidth={false}>
            <RenderPage />
        </Container>
     </Box>
    </>
  );
};

export default Absen;
