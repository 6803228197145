import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  CardHeader,
  TextField,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Trash, Edit2 } from 'react-feather';
import Swal from 'sweetalert2';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import { makeRequest } from '../../api/Api';

const DetilHarian = () => {
  const { state } = useLocation();
  const [dtl, setDtl] = useState([]);
  const [msg, setMsg] = useState('');
  const [notif, setNotif] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [kuantitas, setQty] = useState('');
  const [reload, setReload] = useState(0);
  const [idLap, setId] = useState();
  const [nmPekerjaan, setNama] = useState();
  const [target, setTarget] = useState();
  const [biaya, setBiaya] = useState(0);
  const [waktu, setWaktu] = useState(0);

  const loadDtl = () => {
    makeRequest({
      url: `dtl_harian/${state.idSkp}/${state.idPegawai}`,
      successCallback: (data) => {
        console.log(data);
        setDtl(data.dtl);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const handleClose = () => {
    setDialog(false);
  };

  const edit = (id, pekerjaan, qty, trgt, biayaReal, waktuReal) => {
    setQty(qty);
    setMsg('');
    setId(id);
    setNama(pekerjaan);
    setTarget(trgt);
    setBiaya(biayaReal);
    setWaktu(waktuReal);
    setDialog(true);
  };

  const ubahData = () => {
    makeRequest({
      values: { 
        id: idLap, 
        hasil_pekerjaan: kuantitas, 
        biaya_realisasi: biaya, 
        waktu_realisasi: waktu 
      },
      url: 'update_harian',
      successCallback: (data) => {
        console.log(data);
        setQty('');
        setMsg(data.msg);
        setId('');
        setNama('');
        setTarget('');
        setDialog(false);
        setReload(reload + 1);
        setNotif(true);
      },
      failureCallback: (err) => {
        console.log(err);
      }

    });
  };

  const hapus = (id) => {
    Swal.fire({
      title: 'Yakin Hapus Data Ini?',
      text: 'Data Akan Terhapus Dari Sistem',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus Data!',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        makeRequest({
          url: `laporanHarian/${id}`,
          successCallback: (data) => {
            setMsg(data.msg);
            setNotif(true);
            setReload(reload + 1);
          },
          failureCallback: (err) => {
            console.log(err);
          },
          requestType: 'DELETE',
        });
      }
    });
  };

  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={() => setNotif(false)}>
      Ok
    </Button>
  );

  useEffect(() => {
    loadDtl();
  }, [reload]);
  return (
        <>
        <Helmet>
            <title>Laporan Harian</title>
        </Helmet>
      {
          notif ? (
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={notif}
              autoHideDuration={2000}
              message={msg}
              action={action}
            />
          ) : (
            null
          )
        }
      <Box sx={{ m: 3 }}>
        <Card>
            <CardHeader title="Pekerjaan" />
            <Divider />
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                No
                            </TableCell>
                            <TableCell>
                                Tgl
                            </TableCell>
                            <TableCell>
                                Pekerjaan
                            </TableCell>
                            <TableCell>
                                Kuantitas
                            </TableCell>
                            <TableCell>
                                Waktu Pengerjaan
                            </TableCell>
                            <TableCell colSpan="2">
                                Biaya
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                           dtl.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan="2">
                                    Belum Ada Data
                                </TableCell>
                            </TableRow>
                           ) : (
                             dtl.map((job, idx) => (
                             <TableRow key={job.id}>
                                 <TableCell>
                                     {idx + 1}
                                 </TableCell>
                                 <TableCell>
                                     {moment(job.tgl).format('DD-MM-YYYY')}
                                 </TableCell>
                                 <TableCell>
                                    {job.pekerjaan}
                                 </TableCell>
                                 <TableCell>
                                    {job.hasil_pekerjaan}
                                    <span>  </span> 
                                    {job.target_output}
                                 </TableCell>
                                 <TableCell>
                                  {job.waktu_realisasi}
                                  <span>  </span>
                                  {job.waktu}
                                 </TableCell>
                                 <TableCell>
                                  {job.biaya_realisasi}
                                 </TableCell>
                                 <TableCell>
                                     {
                                         job.status === '0' ? (
                                          <>
                                            <IconButton sx={{ color: '#F4B400' }} title="Edit Data" onClick={() => { edit(job.id, job.pekerjaan, job.hasil_pekerjaan, job.target_output, job.biaya_realisasi, job.waktu_realisasi); }}>
                                                <Edit2 />
                                            </IconButton>
                                            <IconButton sx={{ color: '#f44336' }} title="Hapus Data" onClick={() => { hapus(job.id); }}>
                                              <Trash />
                                            </IconButton>
                                          </>
                                         ) : (
                                            <Typography> 
                                                Laporan Sudah Disetujui
                                            </Typography>
                                         )
                                     }
                                     
                                 </TableCell>
                             </TableRow>
                             ))
                           )
                        }
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
        <Dialog open={dialog} onClose={handleClose} fullWidth>
          <DialogTitle>Edit Laporan Harian</DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText>
              Pekerjaan:
              <span> </span>
              {nmPekerjaan}
            </DialogContentText>
            <TextField 
              margin="dense"
              label="Kuantitas"
              type="text"
              name="hasil"
              onChange={(event) => { setQty(event.target.value); }}
              fullWidth
              autoFocus
              value={kuantitas}
            />
            <TextField 
              margin="dense"
              label="Biaya"
              type="number"
              name="biaya"
              onChange={(event) => { setBiaya(event.target.value); }}
              fullWidth
              value={biaya}
            />
            <TextField 
              margin="dense"
              label="Waktu Pengerjaan (Hari)"
              type="number"
              name="waktu"
              onChange={(event) => { setWaktu(event.target.value); }}
              fullWidth
              value={waktu}
            />
            <DialogContentText>
              Satuan Kuantitas:
              <span> </span>
               {target}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Batal</Button>
            <Button onClick={ubahData}>Simpan</Button>
          </DialogActions>
        </Dialog>
      </Box>
        </>
  );
};

export default DetilHarian;
