import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Input,
  CircularProgress
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { makeRequest, uploadFile } from '../../api/Api';

const EditPegawai = ({ pegawai }) => {
  const [nmJabatan, setJabatan] = useState([{ id: 0, nama_jabatan: '--Pilih Jabatan--' }]);
  const fotoPegawaiOld = pegawai.foto;
  const fotoPegawai = `https://www.skp.kunyit.tanahlautkab.go.id/backend/upload/pegawai/${fotoPegawaiOld}`;

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');

  const navigate = useNavigate();

  const [inputPegawai, setInput] = useState({
    nama: pegawai.nama,
    nip: pegawai.nip,
    jk: pegawai.jk,
    jabatan: pegawai.jabatan,
    golongan: '',
    tpt_lahir: pegawai.tpt_lahir,
    tgl_lahir: pegawai.tgl_lahir,
    no_absen: pegawai.no_absen
  });
  const [fotoInfo, setCurrentFoto] = useState({
    currentFile: undefined,
    previewImage: undefined,
    progress: 0,
    message: '',
    isError: false,
  });

  const jk = [
    {
      value: 'L',
      label: 'Laki-Laki'
    },
    {
      value: 'P',
      label: 'Perempuan'
    },
  ];

  const handleChange = (event) => {
    setInput({
      ...inputPegawai,
      [event.target.name]: event.target.value
    });
  };

  const selectFoto = (event) => {
    setCurrentFoto({
      currentFile: event.target.files[0],
      previewImage: URL.createObjectURL(event.target.files[0]),
      progress: 0,
      message: ''
    });
  };

  const uploadFoto = (createCallBack) => {
    uploadFile({
      url: 'foto_pegawai',
      file: fotoInfo.currentFile,
      progressCallback: (dataProgress) => {
        setCurrentFoto({ ...fotoInfo, progress: dataProgress });
      },
      successCallback: (data) => {
        localStorage.setItem('foto', data.file);
        createCallBack();
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const updatePegawai = (values) => {
    // console.log(values);
    makeRequest({
      url: 'pegawai',
      values,
      successCallback: (data) => {
        setMsg(data.msg);
        setOpen(true);
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const update = () => {
    if (fotoInfo.currentFile === undefined) {
      const values = { ...inputPegawai, foto: fotoPegawaiOld, id: pegawai.peg_id };
      updatePegawai(values);
    } else {
      uploadFoto(() => {
        updatePegawai({ ...inputPegawai, foto: localStorage.getItem('foto'), id: pegawai.peg_id });
      });
    }
  };

  const loadJabatan = () => {
    makeRequest({
      url: 'jabatan',
      successCallback: (data) => {
        setJabatan(data.jabatan);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const batal = () => {
    navigate('/app/customers', { replace: true });
  };

  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={batal}>
      Ok
    </Button>
  );

  useEffect(() => {
    loadJabatan();
  }, []);

  return (
  <>
    <Helmet>
      <title>Edit Pegawai</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      {
          open ? (
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={open}
              autoHideDuration={2000}
              message={msg}
              action={action}
            />
          ) : (
            null
          )
        }
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <Card>
              <CardContent>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Avatar 
                    src={(fotoInfo.previewImage === undefined) ? fotoPegawai : fotoInfo.previewImage}
                    sx={{
                      height: 100,
                      width: 100
                    }}
                  />
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    Foto Aparat/Pegawai Desa
                  </Typography>
                  {
                      fotoInfo.currentFile && (
                      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress variant="determinate" value={fotoInfo.progress} />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography variant="caption" color="textSecondary">{`${fotoInfo.progress}%`}</Typography>
                        </Box>
                      </Box>
                      )
                    }                  
                </Box>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  fullWidth
                  variant="contained"
                  component="label"
                >
                  Upload Foto
                  <input type="file" hidden onChange={selectFoto} />
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <Card>
              <CardHeader
                subheader="Data Pegawai"
                title="Edit Data Pegawai"
              />
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Nama Lengkap"
                      name="nama"
                      onChange={handleChange}
                      required
                      value={inputPegawai.nama}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="NIP"
                      name="nip"
                      onChange={handleChange}
                      required
                      value={inputPegawai.nip}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Nomor Absen"
                      name="no_absen"
                      onChange={handleChange}
                      required
                      value={inputPegawai.no_absen}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Jenis Kelamin"
                      name="jk"
                      onChange={handleChange}
                      required
                      select
                      value={inputPegawai.jk}
                      variant="outlined"
                      SelectProps={{ native: true }}
                    >
                    {
                      jk.map((option) => (
                        <option value={option.value} key={option.value}>
                          {option.label}
                        </option>
                      ))
                    }
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Jabatan"
                      name="jabatan"
                      onChange={handleChange}
                      required
                      select
                      value={inputPegawai.jabatan}
                      variant="outlined"
                      SelectProps={{ native: true }}
                    >
                      {
                        nmJabatan.map((data) => (
                          <option value={data.id} key={data.id}>
                            {data.nama_jabatan}
                          </option>
                        ))
                      }
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Tempat Lahir"
                      name="tpt_lahir"
                      required
                      value={inputPegawai.tpt_lahir}
                      variant="outlined"
                      onChange={handleChange}
                    /> 
                  </Grid>
                  <Grid 
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField 
                      fullWidth
                      label="Tanggal Lahir"
                      name="tgl_lahir"
                      required
                      value={inputPegawai.tgl_lahir}
                      variant="outlined"
                      type="date"
                      onChange={handleChange}
                    /> 
                  </Grid>                  
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2
                }}
              >
                <Button
                  sx={{ backgroundColor: '#f44336' }}
                  variant="contained"
                  onClick={batal}
                >
                  Batal
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={update}
                >
                  Simpan
                </Button> 
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
  );
};
export default EditPegawai;
