const getItem = (itemName) => localStorage.getItem(itemName);

const saveItem = (itemName, itemValue) => {
  localStorage.setItem(itemName, itemValue);
};

export const loadClients = () => JSON.parse(getItem('clients'));

const getFilteredClients = (clientId) => {
  return loadClients().filter(({ id }) => clientId !== id);
};

export const loadUser = () => JSON.parse(getItem('user'));

export const saveUser = (user) => {
  saveItem('user_level', JSON.stringify(user));
};

export const loadJWT = () => getItem('token');

export const saveJWT = (token) => {
  saveItem('token', token);
};

export const loadIdPegawai = () => getItem('id_pegawai');

export const saveIdPeg = (idPeg) => {
  saveItem('id_pegawai', idPeg);
};

export const loadDataPegawai = () => getItem('pegawai');

export const saveDataPegawai = (pegawai) => {
  saveItem('pegawai', pegawai);
};

export const loadNilai = () => getItem('nilaiCapaian');

export const saveNilai = (nilai) => {
  saveItem('nilaiCapaian', nilai);
};

export const loadPegAbsen = () => getItem('abs_peg');

export const savePegAbsen = (pegAbsen) => {
  saveItem('abs_peg', pegAbsen);
};

export const findClient = (clientId) => loadClients().find(({ id }) => id === clientId);

export const saveClients = (clients) => {
  saveItem('clients', JSON.stringify(clients));
};

export const addClient = (client) => {
  saveClients([...loadClients(), client]);
};

export const updateClient = (client) => {
  saveClients([...getFilteredClients(client.id), client]);
};

export const deleteClient = ({ id }) => {
  saveClients(getFilteredClients(id));
};

export const clearState = () => {
  localStorage.clear();
};
