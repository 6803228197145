import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  CardHeader,
  TextField,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FilePlus, Search } from 'react-feather';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import { makeRequest } from '../../api/Api';
import { loadIdPegawai } from '../../api/LocalStorage';

const LaporanHarian = () => {
  const idPegawai = loadIdPegawai();
  const bulan = ['--Pilih Bulan--', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  const cek = moment(new Date(), 'YYYY/MM/DD');
  const [bulanSekarang, setBln] = useState(bulan[cek.format('M')]);
  const [tahunSekarang, setThn] = useState(cek.format('Y'));
  const [pekerjaan, setPekerjaan] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [idSkp, setId] = useState();
  const [nmPekerjaan, setNama] = useState();
  const [target, setTarget] = useState();
  const [kuantitas, setQty] = useState('');
  const [biaya, setBiaya] = useState(0);
  const [waktu, setWaktu] = useState(0);
  const [msg, setMsg] = useState('');
  const [notif, setNotif] = useState(false);

  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    bulan: bulanSekarang,
    tahun: tahunSekarang
  });

  const handleChange = (evt) => {
    setFilter({
      ...filter,
      [evt.target.name]: evt.target.value,
    });
  };
  
  const cari = () => {
    setPekerjaan([]);
    makeRequest({
      url: `lap_harian/${idPegawai}/${filter.bulan}/${filter.tahun}`,
      successCallback: (data) => {
        setPekerjaan(data.skp);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const loadPekerjaan = () => {
    makeRequest({
      url: `lap_harian/${idPegawai}/${bulan[cek.format('M')]}/${cek.format('Y')}`,
      successCallback: (data) => {
        setPekerjaan(data.skp);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const inputLaporan = (skpId, namaPek, trgt) => {
    setMsg('');
    setId(skpId);
    setNama(namaPek);
    setTarget(trgt);
    setDialog(true);
  };

  const simpan = () => {
    makeRequest({
      values: { 
        id_pegawai: idPegawai, 
        id_skp: idSkp, 
        hasil_pekerjaan: kuantitas, 
        biaya_realisasi: biaya,
        waktu_realisasi: waktu
      },
      url: 'laporanHarian',
      successCallback: (data) => {
        console.log(data);
        setMsg(data.msg);
        setQty('');
        setNotif(true);
        setDialog(false);
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const handleClose = () => {
    setDialog(false);
  };

  const lihatLaporan = (skpId) => {
    navigate('/app/detil_lap', {
      state: {
        idSkp: skpId,
        idPegawai
      }
    });
  };

  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={() => setNotif(false)}>
      Ok
    </Button>
  );

  useEffect(() => {
    loadPekerjaan();
  }, []);

  return (
     <>
      <Helmet>
        <title>Laporan Harian</title>
      </Helmet>
      {
          notif ? (
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={notif}
              autoHideDuration={2000}
              message={msg}
              action={action}
            />
          ) : (
            null
          )
        }
      <Box sx={{ m: 3 }}>
        <Card>
          <CardHeader title="Cari Data Pekerjaan" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField 
                  fullWidth
                  label="Bulan"
                  variant="outlined"
                  name="bulan"
                  select
                  SelectProps={{ native: true }}
                  value={filter.bulan}
                  onChange={handleChange}
                >
                    {
                      bulan.map((bln) => (
                        <option key={bln} value={bln}>
                          {bln}
                        </option>
                      ))
                              }
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Tahun"
                  variant="outlined"
                  name="tahun"
                  value={filter.tahun}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ mt: 1 }}
                  onClick={cari}
                >
                Cari
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ m: 3 }}>
        
        <Card>
            <CardHeader title="Pekerjaan" />
            <Divider />
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                No
                            </TableCell>
                            <TableCell>
                                Pekerjaan
                            </TableCell>
                            <TableCell>
                                Target Kuantitas
                            </TableCell>
                            <TableCell colSpan="2">
                                Target Waktu
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            pekerjaan.map((job, idx) => (
                             <TableRow key={job.id}>
                                 <TableCell>
                                     {idx + 1}
                                 </TableCell>
                                 <TableCell>
                                     {job.pekerjaan}
                                 </TableCell>
                                 <TableCell>
                                     {job.target_kuantitas}
                                     <span> </span>
                                     {job.target_output}
                                 </TableCell>
                                 <TableCell>
                                     {job.target_waktu}
                                     <span> </span>
                                     {job.sat_waktu}
                                 </TableCell>
                                 <TableCell>
                                    <IconButton sx={{ color: '#1f9c40' }} title="Input Laporan Harian" onClick={() => { inputLaporan(job.skp_id, job.pekerjaan, job.target_output); }}>
                                         <FilePlus />
                                    </IconButton>
                                    <IconButton sx={{ color: '#F4B400' }} title="Lihat Laporan" onClick={() => { lihatLaporan(job.skp_id); }}>
                                         <Search />
                                    </IconButton>
                                 </TableCell>
                             </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
        <Dialog open={dialog} onClose={handleClose} fullWidth>
          <DialogTitle>Input Laporan Harian</DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText>
              Pekerjaan:
              <span> </span>
              { nmPekerjaan }
            </DialogContentText>
            <TextField 
              margin="dense"
              label="Kuantitas"
              type="text"
              name="hasil"
              onChange={(event) => { setQty(event.target.value); }}
              fullWidth
              autoFocus
            />
            <TextField 
              margin="dense"
              label="Biaya"
              type="number"
              name="biaya"
              onChange={(event) => { setBiaya(event.target.value); }}
              fullWidth
            />
            <TextField 
              margin="dense"
              label="Waktu Pengerjaan (Hari)"
              type="number"
              name="waktu"
              onChange={(event) => { setWaktu(event.target.value); }}
              fullWidth
            />
            <DialogContentText>
              Satuan Kuantitas:
              <span> </span>
               { target }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Batal</Button>
            <Button onClick={simpan}>Simpan</Button>
          </DialogActions>
        </Dialog>
      </Box>
      
     </>
  );
};

export default LaporanHarian;
