import { 
  Button,
  Card, CardContent, CardHeader, Grid, TextField 
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import { useNavigate, useLocation } from 'react-router-dom';
import { loadPegAbsen } from '../../api/LocalStorage';
import { makeRequest } from '../../api/Api';

const AddAbsen = () => {
  const [tglSekarang, setTgl] = useState(moment().format('YYYY-MM-DD'));
  const [jamDatang, setJamDatang] = useState(moment().format('HH:mm'));
  const [jamPulang, setJamPulang] = useState(moment().format('HH:mm'));
  const [btn, setBtn] = useState(false);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const pegawai = JSON.parse(loadPegAbsen());

  const simpan = () => {
    const values = {
      no_absen: pegawai.no_absen,
      tgl: tglSekarang,
      time_table: 'Daytime',
      on_duty: jamDatang,
      off_duty: jamPulang,
      normal: 1,
      absent: 'True',
      must_c_in: 'True',
      must_c_out: 'True',
      department: 'Kunyit'
    };
    // console.log(values);
    makeRequest({
      url: 'absen',
      values,
      successCallback: (data) => {
        setMsg(data.msg);
        setOpen(true);
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={() => setOpen(false)}>
        Ok
    </Button>
  );

  return (
    <>
          {
              open ? (
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  open={open}
                  autoHideDuration={2000}
                  message={msg}
                  action={action}
                />
              ) : (
                null
              )
          }
      <Card>
          <CardHeader title="Tambah Absen Pegawai" />
          <CardContent>
            Nama :
            {'\n'} 
            {pegawai.nama}
          </CardContent>
          <CardContent>
            <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                    <TextField 
                      margin="dense"
                      name="tgl_absen"
                      label="Tanggal Absen"
                      value={tglSekarang}
                      type="date"
                      fullWidth
                      onChange={(evt) => { setTgl(evt.target.value); }}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <TextField 
                      margin="dense"
                      name="jam_datang"
                      label="Jam Datang"
                      type="time"
                      value={jamDatang}
                      onChange={(evt) => { setJamDatang(evt.target.value); }}
                      fullWidth
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <TextField 
                      margin="dense"
                      name="jam_datang"
                      label="Jam Pulang"
                      type="time"
                      value={jamPulang}
                      onChange={(evt) => { setJamPulang(evt.target.value); setBtn(true); }}
                      fullWidth
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ mt: 2 }}
                      onClick={simpan}
                      disabled={!btn}
                    >
                        Simpan
                    </Button>
                </Grid>
            </Grid>
          </CardContent>
      </Card>
    </>
  );
};
export default AddAbsen;
