import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton
} from '@material-ui/core';
import { Edit, Trash2 } from 'react-feather';
import Snackbar from '@material-ui/core/Snackbar';
import Swal from 'sweetalert2';
import moment from 'moment';
import { makeRequest } from '../../api/Api';
import { loadDataPegawai } from '../../api/LocalStorage';

const NilaiPerilaku = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [perilaku, setPerilaku] = useState([]);
  const dataPegawai = JSON.parse(loadDataPegawai());
  const idPegawai = dataPegawai.id;
  const [reload, setReload] = useState(0);

  const bulan = ['--Pilih Bulan--', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  const cek = moment(new Date(), 'YYYY/MM/DD');
  const bulanSekarang = cek.format('M');
  const tahunSekarang = cek.format('Y');
  const [idPerilaku, setId] = useState('');
  const [nilai, setNilai] = useState(0);
  const [bln, setBulan] = useState(bulan[bulanSekarang]);
  const [thn, setThn] = useState(tahunSekarang);
  const [nilaiPerilaku, setNilaiPerilaku] = useState([]);

  const [cariBln, setCariBln] = useState(bulan[bulanSekarang]);
  const [cariThn, setCariThn] = useState(tahunSekarang);

  const defPerilaku = [
    { id: '0', nama: '--Pilih Perilaku Kerja--' }
  ];

  const loadPerilaku = () => {
    setPerilaku([]);
    makeRequest({
      url: 'perilakuKerja',
      successCallback: (data) => {
        const dtPerilaku = defPerilaku.concat(data.perilaku);
        setPerilaku(dtPerilaku);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const loadNilai = () => {
    makeRequest({
      url: `/nilai/${idPegawai}/${bln}/${thn}`,
      successCallback: (data) => {
        setNilaiPerilaku(data.nilai);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const cariNilai = () => {
    setNilaiPerilaku([]);
    makeRequest({
      url: `/nilai/${idPegawai}/${cariBln}/${cariThn}`,
      successCallback: (data) => {
        console.log(data);
        setNilaiPerilaku(data.nilai);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const HitungJumlah = () => {
    let jml = 0;
    if (nilaiPerilaku.length > 0) {
      const jumlahNilai = nilaiPerilaku.reduce((akumulasi, item) => akumulasi + parseInt(item.nilai, 10), 0);
      jml = jumlahNilai / nilaiPerilaku.length;
    } else {
      jml = 0;
    }
    return jml;
  };

  const kategori = (val) => {
    let ket = '';
    if (val <= 50) {
      ket = 'Buruk';      
    } else if (val <= 60) {
      ket = 'Kurang';
    } else if (val <= 75) {
      ket = 'Cukup';
    } else if (val <= 99) {
      ket = 'Baik';
    } else {
      ket = 'Sangat Baik';
    }
    return ket;
  };

  const simpan = () => {
    const values = {
      id_pegawai: idPegawai,
      id_perilaku: idPerilaku,
      nilai,
      bulan: bln,
      tahun: thn
    };
    makeRequest({
      url: 'nilai_perilaku',
      values,
      successCallback: (data) => {
        setMsg(data.msg);
        setBulan(bulan[bulanSekarang]);
        setThn(tahunSekarang);
        setId('');
        setNilai(0);
        setOpen(true);
        setReload(reload + 1);
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const hapus = (id) => {
    Swal.fire({
      title: 'Yakin Hapus Data Ini?',
      text: 'Data Akan Terhapus Dari Sistem',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus Data!',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        makeRequest({
          url: `hapus_nilai/${id}`,
          successCallback: (data) => {
            setMsg(data.msg);
            setReload(reload + 1);
            setOpen(true);
          },
          failureCallback: (err) => {
            console.log(err);
          },
          requestType: 'GET',
        });
      }
    });
  };
    
  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={() => setOpen(false)}>
        Ok
    </Button>
  );

  useEffect(() => {
    loadPerilaku();
    loadNilai();
  }, [reload]);

  return (
    <>
        <Helmet title="Penilaian Perilaku" />
        <Box sx={{ mb: 3 }}>
            {
              open ? (
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  open={open}
                  autoHideDuration={2000}
                  message={msg}
                  action={action}
                />
              ) : (
                null
              )
            }
            <Card sx={{ m: 3 }}>
                <CardHeader title="Form Pencarian" />
                <Divider />
                <CardContent>
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                      <Grid item xs={4} md={4}>
                        <TextField
                          fullWidth
                          label="Bulan"
                          variant="outlined"
                          name="bulan"
                          select
                          SelectProps={{ native: true }}
                          value={cariBln}
                          onChange={(evt) => { setCariBln(evt.target.value); }}
                        >
                        {
                            bulan.map((b) => (
                                <option key={b} value={b}>
                                    {b}
                                </option>
                            ))
                              }
                        </TextField>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <TextField 
                          fullWidth
                          label="Tahun"
                          variant="outlined"
                          name="tahun"
                          value={cariThn}
                          onChange={(evt) => { setCariThn(evt.target.value); }}
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={cariNilai}
                        >
                            Cari
                        </Button>
                      </Grid>
                  </Grid>
                </CardContent>
            </Card>
            <Card sx={{ m: 3 }}>
                <CardHeader title="Form Tambah Penilaian" />
                <Divider />
                <CardContent>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                      <Grid item xs={3} md={3}>
                        <TextField
                          fullWidth
                          label="Bulan"
                          variant="outlined"
                          name="bulan"
                          select
                          SelectProps={{ native: true }}
                          value={bln}
                          onChange={(evt) => { setBulan(evt.target.value); }}
                        >
                        {
                            bulan.map((b) => (
                                <option key={b} value={b}>
                                    {b}
                                </option>
                            ))
                              }
                        </TextField>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <TextField 
                          fullWidth
                          label="Tahun"
                          variant="outlined"
                          name="tahun"
                          value={thn}
                          onChange={(evt) => { setThn(evt.target.value); }}
                        />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <TextField
                          fullWidth
                          label=""
                          variant="outlined"
                          name="perilaku"
                          select
                          SelectProps={{ native: true }}
                          value={idPerilaku}
                          onChange={(evt) => { setId(evt.target.value); }}
                        >
                        {
                            perilaku.map((per) => (
                                <option key={per.id} value={per.id}>
                                    {per.nama}
                                </option>
                            ))
                              }
                        </TextField>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <TextField 
                          fullWidth
                          label="Nilai"
                          variant="outlined"
                          name="nilai"
                          value={nilai}
                          onChange={(evt) => { setNilai(evt.target.value); }}
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Button
                          fullWidth
                          color="primary"
                          variant="contained"
                          onClick={simpan}
                        >
                            Simpan
                        </Button>
                      </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={{ m: 3 }}>
                <CardHeader title="Data Penilaian" />
                <Divider />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={4} md={3}> 
                      <Typography variant="body1" align="left">
                          Nama Lengkap:
                          {'\n'}
                          {dataPegawai.nama}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={3}> 
                      <Typography variant="body1" align="center">
                          Jabatan:
                          {'\n'}
                          {dataPegawai.jabatan}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={3}> 
                      <Typography variant="body1" align="center">
                          Nilai Rata - Rata:
                          {'\n'}
                          <HitungJumlah />
                          {'\n'}
                          {kategori(HitungJumlah())}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    No
                                </TableCell>
                                <TableCell>
                                    Perilaku Kerja
                                </TableCell>
                                <TableCell colSpan="2">
                                    Nilai
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                nilaiPerilaku.length === 0 ? (
                                  <TableRow>
                                      <TableCell colSpan="2">
                                          Belum Ada data
                                      </TableCell>
                                  </TableRow>
                                ) : (
                                  nilaiPerilaku.map((n, idx) => (
                                    <TableRow key={n.nilai_id}>
                                        <TableCell>
                                            {idx + 1}
                                        </TableCell>
                                        <TableCell>
                                            {n.nama}
                                        </TableCell>
                                        <TableCell>
                                            {n.nilai}
                                            {'\n'}
                                            {kategori(n.nilai)}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton sx={{ color: '#f44336' }} title="Hapus" onClick={() => { hapus(n.nilai_id); }}>
                                                <Trash2 />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                  ))
                                )
                            }
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </Box>
    </>
  );
};

export default NilaiPerilaku;
