import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import MasterPerilakuKerja from '../components/master/MasterPerilakuKerja';
import { makeRequest } from '../api/Api';

const PerilakuKerja = () => {
  const [perilaku, setPerilaku] = useState([]);
  const [reload, setReload] = useState(0);

  const handleReload = (val) => {
    setReload(val);
  };

  const loadPerilaku = () => {
    makeRequest({
      url: 'perilakuKerja',
      successCallback: (data) => {
        setPerilaku(data.perilaku);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };
  useEffect(() => {
    loadPerilaku();
  }, [reload]);
  return (
        <>
        <Helmet>
        <title>Data Master Pekerjaan</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
            <Container maxWidth="lg">
              <MasterPerilakuKerja perilakuKerja={perilaku} callBackReload={handleReload} reload={reload} />
            </Container>
        </Box>
        </>
  );
};

export default PerilakuKerja;
