import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import moment from 'moment';
import { makeRequest } from '../../api/Api';
import { loadDataPegawai } from '../../api/LocalStorage';

const FormTarget = () => {
  const [pekerjaan, setPekerjaan] = useState([]);
  
  const bulan = ['--Pilih Bulan--', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  const cek = moment(new Date(), 'YYYY/MM/DD');
  const bulanSekarang = cek.format('M');
  const tahunSekarang = cek.format('Y');
  const [msg, setMsg] = useState('');
  const [open, setOpen] = useState(false);
  const dataPegawai = JSON.parse(loadDataPegawai());

  const [inputTarget, setTarget] = useState({
    id_pegawai: dataPegawai.id,
    id_pekerjaan: '1',
    bobot: 0,
    bulan: bulan[bulanSekarang],
    tahun: tahunSekarang,
    target_kuantitas: '',
    target_output: '',
    target_kualitas: '',
    target_waktu: '',
    target_satuan_waktu: 'Hari',
    target_biaya: ''
  });

  const navigate = useNavigate();

  const handleChange = (evt) => {
    console.log(pekerjaan.find((o) => o.id === inputTarget.id_pekerjaan));
    const obj = pekerjaan.find((o) => o.id === inputTarget.id_pekerjaan);
    setTarget({
      ...inputTarget,
      [evt.target.name]: evt.target.value,
      bobot: obj.bobot
    });
    // console.log(obj.bobot);
  };

  const loadPekerjaan = () => {
    makeRequest({
      url: 'pekerjaan',
      successCallback: (data) => {
        // console.log(data);
        setPekerjaan(data.pekerjaan);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const simpanTarget = () => {
    const values = inputTarget;
    // console.log(values);
    makeRequest({
      url: 'skp',
      values,
      successCallback: (data) => {
        setMsg(data.msg);
        setOpen(true);
        setTarget({
          id_pegawai: dataPegawai.id,
          id_pekerjaan: '1',
          bulan: bulan[bulanSekarang],
          tahun: tahunSekarang,
          target_kuantitas: '',
          target_output: '',
          target_kualitas: '',
          target_waktu: '',
          target_satuan_waktu: 'Hari',
          target_biaya: ''
        });
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const batal = () => {
    navigate('/app/skp', { replace: true });
  };

  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={() => setOpen(false)}>
        Ok
    </Button>
  );

  useEffect(() => {
    loadPekerjaan();
  }, []);

  return (
        <>
          <Box sx={{ mb: 3 }}>
            {
              open ? (
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  open={open}
                  autoHideDuration={2000}
                  message={msg}
                  action={action}
                />
              ) : (
                null
              )
            }
            <Card>
                <CardHeader title="Informasi Pegawai" />
                <Divider />
                <CardContent>
                    <Grid container spacing={2} sx={{ marginTop: 0.5 }}>
                        <Grid item xs={6} md={3}> 
                            <Typography variant="body1" align="left">
                                Nama Lengkap:
                                {'\n'}
                                {dataPegawai.nama}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography variant="body1" align="center">
                                Jabatan:
                                {'\n'} 
                                {dataPegawai.jabatan}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
          </Box>
          <Box sx={{ md: 3 }}>
            <Card>
                <CardHeader title="Form Tambah Pekerjaan" />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}> 
                            <TextField
                              fullWidth
                              label="Pekerjaan"
                              variant="outlined"
                              name="id_pekerjaan"
                              select
                              SelectProps={{ native: true }}
                              value={inputTarget.id_pekerjaan}
                              onChange={handleChange}
                              focused
                            >
                            {
                                pekerjaan.map((data) => (
                                    <option key={data.id} value={data.id}>
                                     {data.pekerjaan}
                                    </option>
                                ))
                            }
                            </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField 
                            fullWidth
                            label="Bulan"
                            variant="outlined"
                            name="bulan"
                            select
                            SelectProps={{ native: true }}
                            value={inputTarget.bulan}
                            onChange={handleChange}
                          >
                              {
                                  bulan.map((bln) => (
                                    <option key={bln} value={bln}>
                                        {bln}
                                    </option>
                                  ))
                              }
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Tahun"
                            variant="outlined"
                            name="tahun"
                            value={inputTarget.tahun}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            label="Kuantitas"
                            variant="outlined"
                            name="target_kuantitas"
                            value={inputTarget.target_kuantitas}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            label="Output"
                            variant="outlined"
                            name="target_output"
                            value={inputTarget.target_output}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            label="Kualitas/Mutu"
                            variant="outlined"
                            name="target_kualitas"
                            value={inputTarget.target_kualitas}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            label="Waktu"
                            variant="outlined"
                            name="target_waktu"
                            value={inputTarget.target_waktu}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            label="Satuan Waktu"
                            variant="outlined"
                            name="target_satuan_waktu"
                            value={inputTarget.target_satuan_waktu}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            label="Biaya"
                            variant="outlined"
                            name="target_biaya"
                            value={inputTarget.target_biaya}
                            onChange={handleChange}
                          />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 2
                  }}
                >
                  <Button
                    sx={{ backgroundColor: '#f44336' }}
                    variant="contained"
                    onClick={batal}
                  >
                    Kembali
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={simpanTarget}
                    disabled={inputTarget.target_kuantitas < 1}
                  >
                    Simpan
                  </Button>
                </Box>
            </Card>
          </Box>
        </>
  );
};

export default FormTarget;
