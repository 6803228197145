import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  CardHeader,
  TextField,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  TablePagination,
  CardActions
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { 
  CheckCircle,
} from 'react-feather';
import Snackbar from '@material-ui/core/Snackbar';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { makeRequest } from '../../api/Api';
import { loadDataPegawai } from '../../api/LocalStorage';

const CeklisKerjaan = () => {
  const dataPegawai = JSON.parse(loadDataPegawai());
  const idPegawai = dataPegawai.id;
  const namaPegawai = dataPegawai.nama;
  const [kerjaan, setKerjaan] = useState([]);
  const [reload, setReload] = useState(0);
  const [dialog, setDialog] = useState(false);
  const [nmPekerjaan, setNama] = useState('');
  const [quantity, setQy] = useState('');
  const [trget, setTarget] = useState('');
  const [nilai, setNilai] = useState('');
  const [idSkp, setIdSkp] = useState('');
  const [idLap, setIdLap] = useState('');
  const [biaya, setBiaya] = useState('');
  const [waktu, setWaktu] = useState('');
  const [satWaktu, setSatuan] = useState('');

  const [msg, setMsg] = useState('');
  const [open, setOpen] = useState(false);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const persetujuan = (namaPekerjaan, qty, output, skpId, lapId, biayaReal, waktuReal, waktuSat) => {
    setNama(namaPekerjaan);
    setQy(qty);
    setTarget(output);
    setIdSkp(skpId);
    setIdLap(lapId);
    setBiaya(biayaReal);
    setWaktu(waktuReal);
    setSatuan(waktuSat);
    setDialog(true);
  };

  const setujui = () => {
    const values = {
      id_skp: idSkp,
      id_lap: idLap,
      realisasi_kualitas: nilai,
      kuantitas: quantity,
      biaya_realisasi: biaya,
      waktu_realisasi: waktu
    };
    makeRequest({
      url: 'realisasi',
      values,
      successCallback: (data) => {
        setMsg(data.msg);
        setOpen(true);
        setNama('');
        setQy('');
        setTarget('');
        setNilai('');
        setReload(reload + 1);
        setDialog(false);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      
    });
  };

  const handleClose = () => {
    setNama('');
    setQy('');
    setTarget('');
    setNilai('');
    setDialog(false);
  };

  const loadLaporan = () => {
    makeRequest({
      url: `/ceklis/${idPegawai}`,
      successCallback: (data) => {
        // console.log(data);
        setKerjaan(data.laporan);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={() => setOpen(false)}>
        Ok
    </Button>
  );

  useEffect(() => {
    loadLaporan();
  }, [reload]);
  
  return (
    <>
      <Helmet>
        <title>Ceklis Laporan Harian</title>
      </Helmet>
      <Box sx={{ m: 3 }}>
      {
        open ? (
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  open={open}
                  autoHideDuration={2000}
                  message={msg}
                  action={action}
                />
        ) : (
          null
        )
      }
        <Card>
            <CardHeader title="Pekerjaan Harian" />
            <Divider />
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                No
                            </TableCell>
                            <TableCell>
                                Tgl Input
                            </TableCell>
                            <TableCell>
                                Pekerjaan
                            </TableCell>
                            <TableCell colSpan="2">
                                Kuantitas
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            kerjaan.slice(page * limit, page * limit + limit).map((job, idx) => (
                                <TableRow key={job.id + 1}>
                                    <TableCell>
                                        {idx + 1}
                                    </TableCell>
                                    <TableCell>
                                        {moment(job.tgl).format('DD-MM-YYYY')}
                                    </TableCell>
                                    <TableCell>
                                        {job.pekerjaan}
                                    </TableCell>
                                    <TableCell>
                                        {job.hasil_pekerjaan}
                                        <span> </span>
                                        {job.target_output}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            job.status === '1' ? (
                                              'Laporan Sudah Disetujui'
                                            ) : (
                                                <IconButton sx={{ color: '#42f557' }} title="Setujui Pekerjaan" onClick={() => { persetujuan(job.pekerjaan, job.hasil_pekerjaan, job.target_output, job.id_skp, job.lap_id, job.biaya_realisasi, job.waktu_realisasi, job.sat_waktu); }}>
                                                    <CheckCircle />
                                                </IconButton>
                                            )
                                        }
                                        
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </CardContent>
            <CardActions>
                <TablePagination
                  component="div"
                  count={kerjaan.length}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[10, 20, 30]}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                />
            </CardActions>
        </Card>
        
        <Dialog open={dialog} onClose={handleClose} fullWidth>
            <DialogTitle>Informasi Pekerjaan</DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText>
                    Nama Pegawai : 
                    <span> </span>
                    {namaPegawai}
                </DialogContentText>
                <DialogContentText>
                    Pekerjaan : 
                    <span> </span>
                    {nmPekerjaan}
                </DialogContentText>
                <DialogContentText>
                    Kuantitas : 
                    <span> </span>
                    {quantity}
                    <span> </span>
                    {trget}
                </DialogContentText>
                <DialogContentText>
                    Waktu Pengerjaan : 
                    <span> </span>
                    {waktu}
                    <span> </span>
                    {satWaktu}
                </DialogContentText>
                <TextField 
                  margin="dense"
                  label="Mutu Realisasi"
                  type="text"
                  name="nilai"
                  onChange={(event) => { setNilai(event.target.value); }}
                  fullWidth
                  autoFocus
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Batal</Button>
                <Button onClick={setujui}>Setujui</Button>
            </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default CeklisKerjaan;
