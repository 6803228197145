import { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { makeRequest } from '../../api/Api';

const RekapAbsen = () => {
  const bulan = ['--Pilih Bulan--', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  const cek = moment(new Date(), 'YYYY/MM/DD');
  const bulanSekarang = cek.format('M');
  const tahunSekarang = cek.format('Y');
  const [cariBln, setCariBln] = useState(bulan[bulanSekarang]);
  const [cariThn, setCariThn] = useState(tahunSekarang);
  const [rekap, setRekap] = useState([]);
  const [potongan, setPotongan] = useState(0);

  const tanggal = `${cariThn}-${bulan.indexOf(cariBln)}`;
  const jumlahHariKerja = moment(tanggal).daysInMonth() - 8;

  const link = 'https://www.skp.kunyit.tanahlautkab.go.id/backend/';

  console.log(jumlahHariKerja);
  console.log(`${cariThn}-${cariBln}`);
  
  const loadRekap = () => {
    makeRequest({
      url: `rekap/${cariBln}/${cariThn}`,
      successCallback: (data) => {
        setRekap(data.data_rekap);
        setPotongan(data.potongan.jumlah);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const cariRekap = () => {
    makeRequest({
      url: `rekap/${cariBln}/${cariThn}`,
      successCallback: (data) => {
        console.log(data);
        setRekap(data.data_rekap);
        setPotongan(data.potongan.jumlah);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  useEffect(() => {
    loadRekap();
  }, []);

  return (
      <>
       <Card>
           <CardHeader title={`Daftar Kehadiran & Tunjangan ${cariBln} ${cariThn}`} />
           <Divider />            
            <CardContent>
             <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={3} md={3}>
                <TextField
                  fullWidth
                  label="Bulan"
                  variant="outlined"
                  name="bulan"
                  select
                  SelectProps={{ native: true }}
                  value={cariBln}
                  onChange={(evt) => { setCariBln(evt.target.value); }}
                >
                  {
                    bulan.map((b) => (
                      <option key={b} value={b}>
                        {b}
                      </option>
                    ))
                  }
                </TextField>
              </Grid>
              <Grid item xs={3} md={3}>
                <TextField 
                  fullWidth
                  label="Tahun"
                  variant="outlined"
                  name="tahun"
                  value={cariThn}
                  onChange={(evt) => { setCariThn(evt.target.value); }}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={cariRekap}
                >
                    Cari
                </Button>
              </Grid>
              <Grid item xs={3} md={3}>
                <a href={`${link}cetak/${cariBln}/${cariThn}/${jumlahHariKerja}`} target="_blank" rel="noreferrer">
                  <Button
                    color="secondary"
                    variant="contained"
                  >
                      Cetak Pdf
                  </Button>
                </a>
                
              </Grid>
             </Grid>
            </CardContent>
            <Divider />
           <Table>
               <TableHead>
                   <TableRow>
                       <TableCell>
                           No
                       </TableCell>
                       <TableCell>
                           Nama Pegawai
                       </TableCell>
                       <TableCell>
                           Jabatan
                       </TableCell>
                       <TableCell>
                           Kehadiran
                       </TableCell>
                       <TableCell>
                           TPP
                       </TableCell>
                       <TableCell>
                           TPP Diterima
                       </TableCell>
                   </TableRow>
               </TableHead>
               <TableBody>
                 {
                   rekap.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan="5">
                        Belum Ada Data
                      </TableCell>
                    </TableRow>
                   ) : (
                     rekap.map((dt, idx) => (
                        <TableRow key={dt.id}>
                          <TableCell>
                            {idx + 1}
                          </TableCell>
                          <TableCell>
                            {dt.nama}
                          </TableCell>
                          <TableCell>
                            {dt.nama_jabatan}
                          </TableCell>
                          <TableCell>
                            {dt.jml_hadir} 
                            {'\n'}
                            Hari
                          </TableCell>
                          <TableCell>
                            <NumberFormat 
                              value={dt.tunjangan}
                              displayType="text"
                              thousandSeparator
                              prefix="RP."
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat 
                              value={dt.tunjangan - (potongan * (jumlahHariKerja - dt.jml_hadir))}
                              displayType="text"
                              thousandSeparator
                              prefix="RP."
                            />
                          </TableCell>
                        </TableRow>  
                     ))
                   )
                 }
               </TableBody>
           </Table>
       </Card>
      </>
  );
};

export default RekapAbsen;
