import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import DaftarSkp from '../components/skp/DaftarSkp';
import FormTarget from '../components/skp/FormTarget';
import CapaianSkp from '../components/skp/CapaianSkp';
import { makeRequest } from '../api/Api';

const Skp = ({ page }) => {
  const [skp, setSkp] = useState([]);
  const [pegawai, setPegawai] = useState([]);
  const halaman = page.hal;

  const skpHook = (data) => {
    setPegawai(data);
  };

  const RenderPage = () => {
    if (halaman === 'kinerja') {
      return (
        <DaftarSkp dataSkp={skp} callBackData={skpHook} />
      );
    }

    if (halaman === 'target') {
      return (
        <FormTarget dataSkp={pegawai} />
      );
    }

    if (halaman === 'capaian') {
      return (
        <CapaianSkp dataSkp={pegawai} />
      );
    }
    
    return null;
  };

  const loadSkp = () => {
    makeRequest({
      url: 'skp',
      successCallback: (data) => {
        setSkp(data.pegawai);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  useEffect(() => {
    loadSkp();
  }, []);

  return (
      <>
        <Helmet>
            <title>Kinerja Pegawai</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
            <Container maxWidth="lg">
                <RenderPage />
            </Container>
        </Box>
        
      </>
  );
};

export default Skp;
