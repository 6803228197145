import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import moment from 'moment';
import { makeRequest, uploadFile } from '../../api/Api';

const AddPegawai = () => {
  const navigate = useNavigate();
  const [btnSimpan, setBtn] = useState(true);
  const [nmJabatan, setJabatan] = useState([{ id: 0, nama_jabatan: '--Pilih Jabatan--' }]);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [fotoInfo, setCurrentFoto] = useState({
    currentFile: undefined,
    previewImage: undefined,
    progress: 0,
    message: '',
    isError: false,
  });
  const user = {
    avatar: '/static/images/avatars/avatar_6.png',
    city: 'Los Angeles',
    country: 'USA',
    name: 'Katarina Smith',
  };

  const tglSekarang = moment().format('YYYY-MM-DD');
  
  const [inputPegawai, setinputPegawai] = useState({
    nama: '',
    nip: '',
    jk: 'L',
    jabatan: 0,
    golongan: '',
    tpt_lahir: '',
    tgl_lahir: tglSekarang,
    no_absen: ''
  });

  const jk = [
    {
      value: 'L',
      label: 'Laki-Laki'
    },
    {
      value: 'P',
      label: 'Perempuan'
    },
  ];

  const defaultJabatan = [
    { id: 0, nama_jabatan: '--Pilih Jabatan--' }
  ];

  const loadJabatan = () => {
    makeRequest({
      url: 'jabatan',
      successCallback: (data) => {
        const gabung = defaultJabatan.concat(data.jabatan);
        setJabatan(gabung);
      },
      failureCallback: (err) => {

      },
      requestType: 'GET'
    });
  };

  const handleChange = (event) => {
    setinputPegawai({
      ...inputPegawai,
      [event.target.name]: event.target.value
    });
  };

  const selectFoto = (event) => {
    setCurrentFoto({
      currentFile: event.target.files[0],
      previewImage: URL.createObjectURL(event.target.files[0]),
      progress: 0,
      message: ''
    });
  };

  const uploadFoto = (createCallBack) => {
    uploadFile({
      url: 'foto_pegawai',
      file: fotoInfo.currentFile,
      progressCallback: (dataProgress) => {
        setCurrentFoto({ ...fotoInfo, progress: dataProgress });
      },
      successCallback: (data) => {
        localStorage.setItem('foto', data.file);
        createCallBack();
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const createPegawai = () => {
    const ftPegawai = { foto: localStorage.getItem('foto') };
    const values = { ...inputPegawai, ...ftPegawai };
    makeRequest({
      url: 'pegawai',
      values,
      successCallback: (data) => {
        setMsg(data.msg);
        setOpen(true);
        localStorage.removeItem('foto');
        setinputPegawai({
          nama: '',
          nip: '',
          jk: 'L',
          jabatan: 0,
          golongan: '',
          tpt_lahir: '',
          tgl_lahir: tglSekarang,
          no_absen: '',
        });
        setCurrentFoto({
          currentFile: undefined,
          previewImage: undefined,
          progress: 0,
          message: '',
          isError: false,
        });
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const simpanPegawai = () => {
    uploadFoto(() => {
      createPegawai();
    });
  };

  const batal = () => {
    navigate('/app/customers', { replace: true });
  };

  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={() => setOpen(false)}>
      Ok
    </Button>
  );

  useEffect(() => {
    loadJabatan();
  }, []);

  return (
    <>
      <Helmet>
        <title>Pegawai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        {
          open ? (
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={open}
              autoHideDuration={2000}
              message={msg}
              action={action}
            />
          ) : (
            null
          )
        }
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
            >
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Avatar
                      src={(fotoInfo.previewImage === undefined) ? user.avatar : fotoInfo.previewImage}
                      sx={{
                        height: 100,
                        width: 100
                      }}
                    />
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h5"
                    >
                      Foto Aparat/Pegawai Desa
                    </Typography>
                    {
                      fotoInfo.currentFile && (
                      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress variant="determinate" value={fotoInfo.progress} />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography variant="caption" color="textSecondary">{`${fotoInfo.progress}%`}</Typography>
                        </Box>
                      </Box>
                      )
                    }
                  </Box>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    color="primary"
                    fullWidth
                    variant="contained"
                    component="label"
                  >
                    Upload Foto
                    <input type="file" hidden onChange={selectFoto} />
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xs={12}
            >
              <Card>
                <CardHeader
                  subheader="Data Pegawai"
                  title="Tambah Pegawai / Aparat Desa"
                />
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Nama Lengkap"
                        name="nama"
                        onChange={handleChange}
                        required
                        value={inputPegawai.nama}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="NIP"
                        name="nip"
                        onChange={handleChange}
                        required
                        value={inputPegawai.nip}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Nomor Absen"
                        name="no_absen"
                        onChange={handleChange}
                        required
                        value={inputPegawai.no_absen}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Jenis Kelamin"
                        name="jk"
                        onChange={handleChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={inputPegawai.jk}
                        variant="outlined"
                      >
                        {jk.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Jabatan"
                        name="jabatan"
                        onChange={handleChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={inputPegawai.jabatan}
                        variant="outlined"
                      >
                       {
                         nmJabatan.map((data) => (
                           <option
                             key={data.id}
                             value={data.id}
                           >
                             {data.nama_jabatan}
                           </option>
                         ))
                       }
                      </TextField>
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Tempat Lahir"
                        name="tpt_lahir"
                        onChange={handleChange}
                        required
                        value={inputPegawai.tpt_lahir}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      
                      <TextField
                        fullWidth
                        label="Tanggal Lahir"
                        name="tgl_lahir"
                        onChange={handleChange}
                        required
                        value={inputPegawai.tgl_lahir}
                        variant="outlined"
                        type="date"
                      />
                    </Grid>

                  </Grid>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 2
                  }}
                >
                  <Button
                    sx={{ backgroundColor: '#f44336' }}
                    variant="contained"
                    onClick={batal}
                  >
                    Batal
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    onClick={simpanPegawai}
                    disabled={inputPegawai.nama < 1}
                  >
                    Simpan
                  </Button>
                </Box>
              </Card>
            </Grid>
          </Grid>
          
        </Container>
      </Box>
    </>
  );
};

export default AddPegawai;
