import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  Divider,
  CardHeader,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton
} from '@material-ui/core';
import moment from 'moment';
import { makeRequest } from '../../api/Api';
import { loadPegAbsen } from '../../api/LocalStorage';

const Kehadiran = () => {
  const bulan = ['--Pilih Bulan--', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  const cek = moment(new Date(), 'YYYY/MM/DD');
  const bulanSekarang = cek.format('M');
  const tahunSekarang = cek.format('Y');
  const [absensi, setAbsensi] = useState([]);

  const [cariBln, setCariBln] = useState(bulan[bulanSekarang]);
  const [cariThn, setCariThn] = useState(tahunSekarang);
  const dataPegawai = JSON.parse(loadPegAbsen());
  const noAbsen = dataPegawai.no_absen;
  
  const loadAbsen = () => {
    makeRequest({
      url: `kehadiran/${noAbsen}/${cariBln}/${cariThn}`,
      successCallback: (data) => {
        setAbsensi(data.absen);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const cari = () => {
    setAbsensi([]);
    makeRequest({
      url: `kehadiran/${noAbsen}/${cariBln}/${cariThn}`,
      successCallback: (data) => {
        console.log(data);
        setAbsensi(data.absen);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  useEffect(() => {
    loadAbsen();
  }, []);

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Card sx={{ m: 3 }}>
            <CardHeader title="Cari Absensi" />
            <Divider />
                <CardContent>
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                      <Grid item xs={4} md={4}>
                        <TextField
                          fullWidth
                          label="Bulan"
                          variant="outlined"
                          name="bulan"
                          select
                          SelectProps={{ native: true }}
                          value={cariBln}
                          onChange={(evt) => { setCariBln(evt.target.value); }}
                        >
                          {
                            bulan.map((b) => (
                                <option key={b} value={b}>
                                    {b}
                                </option>
                            ))
                          }
                        </TextField>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <TextField 
                          fullWidth
                          label="Tahun"
                          variant="outlined"
                          name="tahun"
                          value={cariThn}
                          onChange={(evt) => { setCariThn(evt.target.value); }}
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={cari}
                        >
                            Cari
                        </Button>
                      </Grid>
                  </Grid>
                </CardContent>
        </Card>
        <Card sx={{ m: 3 }}>
            <CardContent>
            <CardHeader title={`Absensi ${dataPegawai.nama}`} />
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item xs={12} md={12}>
                  <Table>
                      <TableHead>
                          <TableRow>
                            <TableCell>
                                No
                            </TableCell>
                            <TableCell>
                                Tanggal
                            </TableCell>
                            <TableCell>
                                Jam Datang
                            </TableCell>
                            <TableCell>
                                Jam Pulang
                            </TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {
                              absensi.length === 0 ? (
                                <TableRow>
                                  <TableCell colSpan="4">
                                      Belum Ada Data
                                  </TableCell>
                                </TableRow>
                              ) : (
                                absensi.map((abs, idx) => (
                                <TableRow key={abs.id + 1}>
                                    <TableCell>
                                        {idx + 1}
                                    </TableCell>
                                    <TableCell>
                                        {moment(abs.tgl).format('DD-MM-YYYY')}
                                    </TableCell>
                                    <TableCell>
                                        {abs.on_duty}
                                    </TableCell>
                                    <TableCell>
                                        {abs.off_duty}
                                    </TableCell>
                                </TableRow>
                                ))
                              )
                          }
                      </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default Kehadiran;
